var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manual-detail manual-page-layout" },
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            { staticClass: "cardClassDetailForm", attrs: { title: _vm.title } },
            [
              _c("template", { slot: "card-detail" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.data.content) },
                  }),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              staticStyle: { "margin-top": "10px", "padding-bottom": "20px" },
              attrs: { title: "첨부파일" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-upload-list", {
                      attrs: {
                        attachInfo: _vm.attachInfo,
                        editable: false,
                        label: "첨부파일",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }